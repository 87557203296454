var site = site || {};

site.spp = site.spp || {};
site.spp.shareEmail = {
  init: function () {
    this.initShareEmail();
  },
  initShareEmail: function () {
    $('.share-email').bind('click', function (e) {
      e.preventDefault();
      generic.template.get({
        path: '/templates/products/email_a_friend.tmpl',
        forceReload: true,
        urlparams: {
          PRODUCT_ID: $(this).attr('data-productid')
        },
        callback: function (html) {
          site.modal.createForm('email_friend_modal', html);
        }
      });
    });
  }
};

$(function () {
  site.spp.shareEmail.init();
});
